import React from "react";
import {useParams, useNavigate} from "react-router-dom";
import Network from "logic/network";

// @mui material
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Slider from "@mui/material/Slider";
import Icon from "@mui/material/Icon";
import Skeleton from "@mui/material/Skeleton";

// Components
import MDBox from "components/MDComponents/MDBox";
import MDTypography from "components/MDComponents/MDTypography";
import MDButton from "components/MDComponents/MDButton";
import MDInput from "components/MDComponents/MDInput";
import MDSnackbar from "components/MDComponents/MDSnackbar";
import MDDialog from "components/MDComponents/MDDialog";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";



// API
import Config from "config";
const recordType = 'LabelType';
const apiURL = Config.api.url;
const apiVersion = Config.api.version;
const apiController = Config.api.controllers[recordType];


//
function LabelTypeDetail() {
	const { labelTypeId = -1 } = useParams();
	const navigate = useNavigate();
	//
	const [loading, setLoading] = React.useState(true);
	const [saving, setSaving] = React.useState(false);

	//
	const [record, setRecord] = React.useState({
		id: labelTypeId,
		name: '',
		description: '',
		weightThreshold: 0,
		weightThreshold_Possible: 0,
		similarityThreshold: 0.8,
		similarityThreshold_Strict: 0.9
	});
	React.useEffect(() => {
		if (record.id > -1) { // Existing Record
			let isSubscribed = true;
			record.id > -1 && (async () => {
				try {
					const _record = await Network.Get(`https://${apiURL}/${apiVersion}/${apiController}/${record.id}`);
					isSubscribed && setRecord(_record);
					setLoading(false);
				}
				catch (error) {
					showErrorToast(true, { title: 'Record Error', content: `${error}` });
					return;
				}
			})();
			return (() => isSubscribed = false);
		}
		else {
			setLoading(false);
		}
	}, []);


	//
	const updateRecord = (propName, value) => {
		if (value === record[propName]) { return; }

		// RevertValue
		if (_.isUndefined(record.RevertValues?.[propName])) {
			if (!record.RevertValues) { record.RevertValues = {}; }
			record.RevertValues[propName] = record[propName];
		}
		else if (value === record.RevertValues?.[propName]) {
			delete record.RevertValues[propName];
			if (_.isEmpty(record.RevertValues)) { delete record.RevertValues; }
		}

		// Value
		record[propName] = value;
		setRecord({...record});
	};

	// Save
	const saveRecord = async () => {
		if (record.id === -1) {
			return saveRecord_new();
		}
		return saveRecord_existing();
	}

	// Save -  New
	const saveRecord_new = async () => {
		if (record.id > -1) { throw new Error('saveRecord_new, invalid id.'); }
		
		//
		let _record;
		try {
			setSaving(true);
			_record = await Network.Post(`https://${apiURL}/${apiVersion}/${apiController}`, { body: JSON.stringify(_.omit(record, ['id'])) });
			record.id = _record.id;
		}
		catch (error) {
			setSaving(false);
			showErrorToast(true, {
				title: 'Save New Record Error',
				content: `${error}`
			});
			return;
		}

		// Success
		setSaving(false);
		showSuccessToast(true, {
			title: `${recordType} Created!`,
			content: `${recordType}:${record.name} has been created.`
		});
		setRecord(_record);
		return;
	}

	// Save - Existing
	const saveRecord_existing = async () => {
		if (record.id < 0) { throw new Error('saveRecord_existing, invalid id.'); }

		//
		try {
			setSaving(true);
			await Network.Put(`https://${apiURL}/${apiVersion}/${apiController}/${record.id}`, { body: JSON.stringify(record) });
		}
		catch (error) {
			setSaving(false);
			showErrorToast(true, {
				title: 'Update Record Error',
				content: `${error}`
			});
			return;
		}

		// Success
		delete record.RevertValues;
		setSaving(false);
		showSuccessToast(true, {
			title: `${recordType} Updated!`,
			content: `${recordType}:${record.name} has been updated.`
		});
	};

	//
	const revertRecord = () => {
		_.assign(record, record.RevertValues);
		delete record.RevertValues;
		setRecord({...record});
	};


	// ---- Dialogs -------------------------------------------------------------------------------------

	// Delete
	const [deleteDialogOptions, setDeleteDialog] = React.useState({
		open: false,
		title: `Delete ${recordType}?`,
		content: `Deleting a ${recordType} will also delete all records associated with it. This action is permanent. Are you sure?`,
		agreeContent: 'DELETE',
		disagreeContent: 'OOPS, NEVERMIND'
	});
	const onDeleteAgree = () => {
		if (record.id > -1) { deleteRecord(record.id); }
		showDeleteDialog(false);
	};
	const DeleteDialog = (
		<MDDialog {...deleteDialogOptions} close={() => showDeleteDialog(false)} onAgree={onDeleteAgree} bgWhite />
	);
	const showDeleteDialog = (show = true, options = {}) => {
		deleteDialogOptions.open = show;
		setDeleteDialog(_.defaults(options, deleteDialogOptions));
	};
	const warnAndDelete = () => {
		showDeleteDialog(true, {
			title: `Delete ${record.name}?`,
			content: `Deleting a ${recordType} will also delete all records associated with it. This action is permanent. Are you sure?`,
		});
	};
	const deleteRecord = async () => {
		try {
			setSaving(true);
			await Network.Delete(`https://${apiURL}/${apiVersion}/${apiController}/${record.id}`);
		}
		catch (error) {
			setSaving(false);
			showErrorToast(true, { // Error
				title: 'Delete Record Error',
				content: `${error}`
			});
			return;
		}

		// Success
		setSaving(false);
		showSuccessToast(true, {
			title: `${recordType} Deleted!`,
			content: `${recordType}:${record.id} has been deleted.`
		});

		// Navigate // TODO?: Navigate w/ History
		navigate('/labelTypes');
	}


	// ---- Toasts --------------------------------------------------------------------------------------

	// For Consecutive Toasts
	const toastKey = (title) => {
		const now = new Date();
		return `${title}${now.getSeconds()}${now.getMilliseconds()}`;
	};

	// Success
	const [successToastOptions, setSuccessToast] = React.useState({
		open: false,
		title: 'Item Created',
		content: 'The item was sucessfully created! You\'re welcome.',
	});
	const SuccessToast = (
		<MDSnackbar color="success" icon="check" dateTime="Just now" key={toastKey(successToastOptions.title)} title={successToastOptions.title} content={successToastOptions.content} open={successToastOptions.open} onClose={() => showSuccessToast(false)} close={() => showSuccessToast(false)} bgWhite />
	);
	const showSuccessToast = (show = true, options = {}) => {
		successToastOptions.open = show;
		setSuccessToast(_.defaults(options, successToastOptions));
	};

	// Error
	const [errorToastOptions, setErrorToast] = React.useState({
		open: false,
		title: 'Error',
		content: 'There was an error. Thank you.',
	});
	const ErrorToast = (
		<MDSnackbar color="error" icon="warning" dateTime="Just now" key={toastKey(errorToastOptions.title)}  title={errorToastOptions.title} content={errorToastOptions.content} open={errorToastOptions.open} onClose={() => showErrorToast(false)} close={() => showErrorToast(false)} bgWhite />
	);
	const showErrorToast = (show = true, options = {}) => {
		errorToastOptions.open = show;
		setErrorToast(_.defaults(options, errorToastOptions));
	};


	// ---- Breadcrumbs ---------------------------------------------------------------------------------

 	const breadcrumbResolvers = [{
		object:	'ClassificationLabelTypes',
		objectKey:	'name'
	}];


	// ---- Component -----------------------------------------------------------------------------------

	return (
		<DashboardLayout>
			<DashboardNavbar showTitle={false} breadcrumbResolvers={breadcrumbResolvers} />
			<MDBox mt={6} mb={3}>
				<Grid container spacing={3} justifyContent="center">

					<Grid item xs={12} lg={8}>
						<Card>
							<MDBox>
								<Grid container xl={12}>
									<Grid item xs={4} padding={2}><MDTypography variant="h5">{loading ? <Skeleton /> : (record.name || recordType)}</MDTypography></Grid>
									<Grid item xs={8} paddingTop={1.5} paddingRight={1}>
										<Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
											{
												record.id > -1 &&
												<MDBox xs={4} marginLeft={1} marginBottom={0.5}>
													<MDButton variant="gradient" color="error" disabled={saving} onClick={() => { warnAndDelete(); }}>
														<Icon sx={{ fontWeight: "bold" }}>close</Icon>
														&nbsp;Delete
													</MDButton>
												</MDBox>
											}
											{
												record.id > -1 && record.RevertValues && 
												<MDBox xs={4} marginLeft={1} marginBottom={0.5}>
													<MDButton variant="gradient" color="warning" disabled={saving} onClick={() => { revertRecord(); }}>
														<Icon sx={{ fontWeight: "bold" }}>refresh</Icon>
														&nbsp;Revert
													</MDButton>
												</MDBox>
											}
											{
												record.RevertValues && (record.name?.trim().length > 0) && 
												<MDBox xs={4} marginLeft={1} marginBottom={0.5}>
													<MDButton variant="gradient" color="success" disabled={saving} onClick={() => { saveRecord(); }}>
														<Icon sx={{ fontWeight: "bold" }}>check</Icon>
														&nbsp;Save
													</MDButton>
												</MDBox>
											}
										</Grid>
									</Grid>
								</Grid>
							</MDBox>
						</Card>
					</Grid>

					<Grid item xs={12} lg={8}>
						<Card>
							<MDBox p={2} lineHeight={0}>
								<MDTypography variant="h5">Identifier</MDTypography>
								<MDTypography variant="button" color="text" fontWeight="regular">
									Give your LabelType a good name. Think of it as a specific type of classification, like &quot;Bird Species&quot;, or &quot;Political Alignments&quot;. Anything that has identifiable features can be classified.
								</MDTypography>
							</MDBox>
							<MDBox p={2} px={2} >
								<MDBox marginBottom={1.5}>
									{loading ? (<Skeleton variant="rounded" width={210} height={50} />) : (<MDInput required variant="filled" label="Name" value={record.name} helperText="LabelType Names Must Be Unique." autoComplete="off" disabled={false} onChange={(event) => updateRecord('name', (event.target.value))} />)}
								</MDBox>
								{loading ? (<Skeleton variant="rounded" xs={12} height={50} />) : (<MDInput label="Description" value={record.description} placeholder="(Optional)" fullWidth={true} variant="filled" autoComplete="off" disabled={saving} onChange={(event) => updateRecord('description', (event.target.value))} />)}
							
							</MDBox>
						</Card>
					</Grid>

					<Grid item xs={12} lg={8}>
						<Card>
							<MDBox color="inherit">
								<MDBox p={2} lineHeight={0}>
									<MDTypography variant="h5">Weight Thresholds</MDTypography>
									<MDTypography variant="button" color="text" fontWeight="regular">
										The weight threshholds control at what weights a label of this type is considered to match a set of features. These numbers will need to be adjusted manually as part of the tuning process.
									</MDTypography>
								</MDBox>
								<MDBox p={2} px={2}>
									<Grid container spacing={1} xl={12}>
										<Grid item xs={6}>
											{loading ? (<Skeleton variant="rounded" xs={12} height={50} />) : (<MDInput label="Weight" value={record.weightThreshold} type="number" autoComplete="off" fullWidth={true} variant="filled" disabled={saving} onChange={(event) => updateRecord('weightThreshold', (event.target.value))} />)}
										</Grid>
										<Grid item xs={6}>
											{loading ? (<Skeleton variant="rounded" xs={12} height={50} />) : (<MDInput label="Possible" value={record.weightThreshold_Possible} type="number" autoComplete="off" fullWidth={true} variant="filled" disabled={saving} onChange={(event) => updateRecord('weightThreshold_Possible', (event.target.value))} />)}
										</Grid>
									</Grid>
								</MDBox>
							</MDBox>
						</Card>
					</Grid>

					<Grid item xs={12} lg={8}>
						<Card>
							<MDBox p={2} lineHeight={0}>
								<MDTypography variant="h5">Similarity</MDTypography>
								<MDTypography variant="button" color="text" fontWeight="regular">
									When comparing two data points, how similar should they be before they are considered a match? Similarity is measures as a number between 0 and 1 where 1 is an exact match and 0 is completely dissimilar.
								</MDTypography>
							</MDBox>
							<MDBox p={2} px={2}>
								<Grid container spacing={1} xl={12}>
									<Grid item xs={6}>
										{loading ? (<Skeleton variant="rounded" xs={12} height={50} />) : (
											<MDBox>
												<MDInput label="Similarity" value={record.similarityThreshold} fullWidth={true} variant="filled" disabled={saving} autoComplete="off" onChange={(event) => updateRecord('similarityThreshold', (event.target.value))} />
												<MDBox sx={{ marginTop: -3 }}>
													<Slider value={record.similarityThreshold * 100.0} min={0.0} max={100.0} disabled={saving} onChange={(event) => updateRecord('similarityThreshold', (event.target.value / 100.0))} />
												</MDBox>
											</MDBox>
										)}
									</Grid>
									<Grid item xs={6}>
										{loading ? (<Skeleton variant="rounded" xs={12} height={50} />) : (
											<MDBox>
												<MDInput label="Strict" value={record.similarityThreshold_Strict} type="number" fullWidth={true} variant="filled" disabled={saving} autoComplete="off" onChange={(event) => updateRecord('similarityThreshold_Strict', (event.target.value))} />
												<MDBox sx={{ marginTop: -3 }}>
													<Slider value={record.similarityThreshold_Strict * 100.0} min={0.0} max={100.0} disabled={saving} onChange={(event) => updateRecord('similarityThreshold_Strict', (event.target.value / 100.0))} />
												</MDBox>
											</MDBox>
										)}
									</Grid>
								</Grid>
							</MDBox>
						</Card>
					</Grid>

				</Grid>
			</MDBox>
			<Footer />
			{DeleteDialog}
			{SuccessToast}
			{ErrorToast}
		</DashboardLayout>
	);
}

export default LabelTypeDetail;
