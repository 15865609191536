import PropTypes from "prop-types";

//
import React from "react";
import Network from "logic/network";
import { useMaterialUIController } from "context";
//
import colors from "assets/theme/base/colors";
import pxToRem from "assets/theme/functions/pxToRem";


// @mui material
import Icon from "@mui/material/Icon";
import Slider from "@mui/material/Slider";
import Skeleton from "@mui/material/Skeleton";
//
import { Link } from "react-router-dom";

// MD-2 React
import MDBox from "components/MDComponents/MDBox";
import MDTypography from "components/MDComponents/MDTypography";
import MDButton from "components/MDComponents/MDButton";
import MDInput from "components/MDComponents/MDInput";
import MDSnackbar from "components/MDComponents/MDSnackbar";
import MDDialog from "components/MDComponents/MDDialog";

//
import DataTable from "components/Tables/DataTable";
import _ from "lodash";


// API
import Config from "config";
const recordType = 'LabelType';
const apiURL = Config.api.url;
const apiVersion = Config.api.version;
const apiController = Config.api.controllers[recordType];


// Record
const newRecord = {
	id: -1,
	name: '',
	description: '',
	weightThreshold: 0,
	weightThreshold_Possible: 0,
	similarityThreshold: 0.8,
	similarityThreshold_Strict: 0.9
};
const record_requiredFields = ['name'];

// Columns
export const Columns = [
	{ Header: "name", accessor: "name", align: "left", minWidth: 300 },
	{ Header: "weight", accessor: "weightT", align: "center", minWidth: 52 },
	{ Header: "possible", accessor: "weightT_Possible", align: "center", minWidth: 50 },
	{ Header: "similarity", accessor: "similarityT", align: "center", minWidth: 80 },
	{ Header: "strict", accessor: "similarityT_Strict", align: "center", minWidth: 80 },
	{ Header: "action", accessor: "action", align: "center", minWidth: 80 }
];


// ---- Table Component -----------------------------------------------------------------------------
export default function LabelTypesTable({setShowProgress}) {
	//
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;

	//
	const [loading, setLoading] = React.useState(true);
	const [saving, setSaving] = React.useState([]);
	const [editing, setEditing] = React.useState([]);

	//
	const isSaving = (saving.length > 0);

	
	// Records
	const [Records, setRecords] = React.useState([]);
	React.useEffect(() => {
		let isSubscribed = true;
		(async () => {
			try {
				setLoading(true);
				setShowProgress?.(true);
				
				const _records = await Network.Get(`https://${apiURL}/${apiVersion}/${apiController}`);
				isSubscribed && setRecords(_records);

				setShowProgress?.(false);
				setLoading(false);
			}
			catch (error) {
				setShowProgress?.(false);
				setLoading(false);
				showErrorToast(true, { title: 'Load Records Error', content: `${error}` });
				return;
			}
		})();
		return (() => isSubscribed = false);
	}, []);


	const updateRecord = (id, propName, value) => {
		const record = _.find(Records, { id });
		if (!record) { return; }
		if (value === record[propName]) { return; }

		// RevertValue
		if (_.isUndefined(record.RevertValues?.[propName])) {
			if (!record.RevertValues) { record.RevertValues = {}; }
			record.RevertValues[propName] = record[propName];
		}
		else if (value === record.RevertValues?.[propName]) {
			delete record.RevertValues[propName];
			if (_.isEmpty(record.RevertValues)) { delete record.RevertValues; }
		}

		// Value
		record[propName] = value;
		setRecords([...Records]);
	};

	const saveRecord = async (id) => {
		const record = _.find(Records, { id });
		if (!record) { return; }

		// New Record
		if (id === -1) {
			let _record;
			const recordCopy = {...record};
			delete recordCopy.id;
			try {
				setSaving((_saving) => (_.union(_saving, [id])));
				setShowProgress?.(true);
				_record = await Network.Post(`https://${apiURL}/${apiVersion}/${apiController}`, { body: JSON.stringify(recordCopy) });
				record.id = _record.id;
			}
			catch (error) {
				setSaving((_saving) => (_.without(_saving, id)));
				setShowProgress?.(false);
				showErrorToast(true, { title: 'Save New Record Error', content: `${error}` });
			}

			// Success
			setSaving((_saving) => (_.without(_saving, id)));
			setShowProgress?.(false);
			showSuccessToast(true, {
				title: `${recordType} Created!`,
				content: `${recordType}:${record.name} has been created.`
			});
			// setRecords([...Records]); // Do I really need to?
			setEditing((_editing) => (_.without(_editing, id)));
			return;
		}


		// Save Existing Record
		const recordCopy = {...record};
		delete recordCopy.RevertValues;
		try {
			setSaving((_saving) => (_.union(_saving, [id])));
			setShowProgress?.(true);
			await Network.Put(`https://${apiURL}/${apiVersion}/${apiController}/${id}`, { body: JSON.stringify(recordCopy) });
		}
		catch (error) {
			setSaving((_saving) => (_.without(_saving, id)));
			setShowProgress?.(false);
			showErrorToast(true, { title: 'Update Record Error', content: `${error}` });
		}

		// Success
		delete record.RevertValues;
		setSaving((_saving) => (_.without(_saving, id)));
		setShowProgress?.(false);
		showSuccessToast(true, {
			title: `${recordType} Updated!`,
			content: `${recordType}:${record.name} has been updated.`
		});
		setEditing((_editing) => (_.without(_editing, id)));
	};

	const deleteRecord = async (id) => {
		try {
			setSaving((_saving) => (_.union(_saving, [id])));
			setShowProgress?.(true);
			await Network.Delete(`https://${apiURL}/${apiVersion}/${apiController}/${id}`);
		}
		catch (error) {
			setSaving((_saving) => (_.without(_saving, id)));
			setShowProgress?.(false);
			showErrorToast(true, { title: 'Delete Record Error', content: `${error}` });
		}

		// Success
		setSaving((_saving) => (_.without(_saving, id)));
		setShowProgress?.(false);
		showSuccessToast(true, {
			title: `${recordType} Deleted!`,
			content: `${recordType}:${id} has been deleted.`
		});
		setRecords(_.filter(Records, (record) => (record.id !== id)));
	}

	const cancelEdit = (id) => {
		if (id === -1) { // New Record
			setRecords(_.filter(Records, (record) => (record.id !== id)));
			setEditing((_editing) => (_.without(_editing, id)));
			return;
		}

		// Existing Record
		const record = _.find(Records, { id });
		_.assign(record, record.RevertValues);
		delete record.RevertValues;
		setEditing((_editing) => (_.without(_editing, id)));
	};

	const addRecord = async () => {
		// Check If Already Adding New Record
		const existingRecord = _.find(Records, { 'id': -1 });
		if (!_.isUndefined(existingRecord)) { return; } // TODO?: Focus on existing, new record

		// Add New Record
		Records.push(_.defaults({}, newRecord));
		setRecords([...Records]);
		setEditing((_editing) => (_.union(_editing, [-1])));
	};


	// ---- Dialogs -------------------------------------------------------------------------------------

	// Delete
	const [deleteDialogOptions, setDeleteDialog] = React.useState({
		open: false,
		title: `Delete ${recordType}?`,
		content: `Deleting a ${recordType} will delete all Labels, Features, & FeatureTypes associated with it. This action is permanent. Are you sure?`,
		agreeContent: 'DELETE',
		disagreeContent: 'OOPS, NEVERMIND',
		deleteId: -1
	});
	const onDeleteAgree = () => {
		if (deleteDialogOptions.deleteId > -1) { deleteRecord(deleteDialogOptions.deleteId); }
		showDeleteDialog(false, { deleteId: -1 });
	};
	const onDeleteDisagree = () => showDeleteDialog(false, { deleteId: -1 });
	const DeleteDialog = (
		<MDDialog {...deleteDialogOptions} close={() => showDeleteDialog(false)} onAgree={onDeleteAgree} onDisagree={onDeleteDisagree} bgWhite />
	);
	const showDeleteDialog = (show = true, options = {}) => {
		deleteDialogOptions.open = show;
		setDeleteDialog(_.defaults(options, deleteDialogOptions));
	};
	const warnAndDelete = (id) => {
		const record = _.find(Records, { id });
		showDeleteDialog(true, {
			title: `Delete ${record.name}?`,
			content: `Deleting the ${record.name} ${recordType} will also delete all records associated with it. This action is permanent. Are you sure?`,
			deleteId: id
		});
	};

	// ---- Toasts --------------------------------------------------------------------------------------

	// For Consecutive Toasts
	const toastKey = (title) => {
		const now = new Date();
		return `${title}${now.getSeconds()}${now.getMilliseconds()}`;
	};

	// Success
	const [successToastOptions, setSuccessToast] = React.useState({
		open: false,
		title: 'Item Created',
		content: 'The item was sucessfully created! You\'re welcome.',
	});
	const SuccessToast = (
		<MDSnackbar color="success" icon="check" dateTime="Just now" key={toastKey(successToastOptions.title)} title={successToastOptions.title} content={successToastOptions.content} open={successToastOptions.open} onClose={() => showSuccessToast(false)} close={() => showSuccessToast(false)} bgWhite />
	);
	const showSuccessToast = (show = true, options = {}) => {
		successToastOptions.open = show;
		setSuccessToast(_.defaults(options, successToastOptions));
	};

	// Error
	const [errorToastOptions, setErrorToast] = React.useState({
		open: false,
		title: 'Error',
		content: 'There was an error. Thank you.',
	});
	const ErrorToast = (
		<MDSnackbar color="error" icon="warning" dateTime="Just now" key={toastKey(errorToastOptions.title)}  title={errorToastOptions.title} content={errorToastOptions.content} open={errorToastOptions.open} onClose={() => showErrorToast(false)} close={() => showErrorToast(false)} bgWhite />
	);
	const showErrorToast = (show = true) => {
		errorToastOptions.open = show;
		setErrorToast({...errorToastOptions});
	};


	// ---- Rows ----------------------------------------------------------------------------------------

	//
	const enabledStyle = ({ cursor: "pointer" });
	const disabledStyle = ({ color: colors.grey[400], textShadow: `1px 1px 2px ${colors.grey[600]};`, cursor: "default" });

	// Normal
	const Row = ({ id, name, description, weightThreshold, weightThreshold_Possible, similarityThreshold, similarityThreshold_Strict }) => ({
		name: (
			<MDBox lineHeight={1} textAlign="left">
				<Link to={`/labelType/${id}`}>
					<MDTypography display="block" variant="caption" color="text" fontWeight="medium">{name || ''}</MDTypography>
					<MDTypography variant="caption">{description || ''}</MDTypography>
				</Link>
			</MDBox>
		),
		weightT: (
			<MDBox>
				<MDTypography variant="caption" color="text" fontWeight="medium">
					{weightThreshold}
				</MDTypography>
			</MDBox>
		),
		weightT_Possible: (
			<MDBox>
				<MDTypography variant="caption" color="text" fontWeight="medium">
					{weightThreshold_Possible}
				</MDTypography>
			</MDBox>
		),
		similarityT: (
			<MDBox>
				<MDTypography variant="caption" color="text" fontWeight="medium">
					{similarityThreshold}
				</MDTypography>
			</MDBox>
		),
		similarityT_Strict: (
			<MDBox>
				<MDTypography variant="caption" color="text" fontWeight="medium">
					{similarityThreshold_Strict}
				</MDTypography>
			</MDBox>
		),
		action: (
			<MDBox>
				<MDTypography variant="caption" color="text" fontWeight="medium" sx={enabledStyle} onClick={() => { setEditing((_editing) => (_.union(_editing, [id]))); }}>
					Edit
				</MDTypography>
					&nbsp; | &nbsp;
				<MDTypography variant="caption" color="text" fontWeight="medium" sx={!isSaving ? enabledStyle : disabledStyle} onClick={() => { if (!isSaving) { warnAndDelete(id); } }}>
					Delete
				</MDTypography>
			</MDBox>
		),
	});

	// Edit
	const inputTextStyle = { fontSize: pxToRem(12), lineHeight: 1.25, fontWeight: 600 }; // TODO: lineHeight: typography.lineHeights.sm or something...
	const inputStyle = _.defaults({ textAlign: "center", color: (darkMode ? "#ffffffcc" : undefined) }, inputTextStyle);
	const Row_Edit = (record) => {
		const { id, name, description, weightThreshold, weightThreshold_Possible, similarityThreshold, similarityThreshold_Strict } = record;
		_.some();
		const canSave = (!isSaving && record.RevertValues);
		const canCancel = (!isSaving || !record.RevertValues);
		const inputDisabled = (isSaving && _.includes(saving, id));
		return ({
			name: (
				<MDBox textAlign="left">
					<MDBox mt={-0.2}><MDInput hiddenLabel size="small" variant="standard" placeholder="Name" value={name} autoComplete="off" disabled={inputDisabled} InputProps={{ disableUnderline: true }} inputProps={{ style: _.defaults({ color: (darkMode ? "#ffffffcc" : "#7b809a") }, inputTextStyle) }} sx={{ width: 300 }} onChange={(event) => updateRecord(id, 'name', event.target.value)} /></MDBox>
					<MDBox mt={-1} mbx={-0.7}><MDInput hiddenLabel size="small" variant="standard" placeholder="Description" value={description} autoComplete="off" disabled={inputDisabled} InputProps={{ disableUnderline: true }} inputProps={{ style: _.defaults({ color: (darkMode ? "#ffffff" : "#344767"), fontWeight: 300 }, inputTextStyle) }} sx={{ width: 300 }} onChange={(event) => updateRecord(id, 'description', event.target.value)} /></MDBox>
				</MDBox>
			),
			weightT: (
				<MDInput variant="standard" size="small" hiddenLabel value={weightThreshold} autoComplete="off" disabled={inputDisabled} inputProps={{ style: _.defaults({ width: 50, marginTop: -1 }, inputStyle) }} onChange={(event) => updateRecord(id, 'weightThreshold', event.target.value)} />
			),
			weightT_Possible: (
				<MDInput variant="standard" size="small" hiddenLabel value={weightThreshold_Possible} autoComplete="off" disabled={inputDisabled} inputProps={{ style: _.defaults({ width: 50, marginTop: -1 }, inputStyle) }} onChange={(event) => updateRecord(id, 'weightThreshold_Possible', event.target.value)} />
			),
			similarityT: (
				<MDBox mt={0.6} sx={{ width: 70 }}>
					<MDInput variant="standard" size="small" hiddenLabel value={similarityThreshold} autoComplete="off" type="number" fullWidth={true} disabled={inputDisabled} InputProps={{ disableUnderline: true }} inputProps={{ style: inputStyle }} onChange={(event) => updateRecord(id, 'similarityThreshold', (event.target.value))} />
					<MDBox mt={-1.9} ml={-0.5}>
						<Slider value={record.similarityThreshold * 100.0} min={0.0} max={100.0} disabled={inputDisabled} size="small" onChange={(event) => updateRecord(id, 'similarityThreshold', (event.target.value / 100.0))} />
					</MDBox>
				</MDBox>
			),
			similarityT_Strict: (
				<MDBox mt={0.6} sx={{ width: 70 }}>
					<MDInput variant="standard" size="small" hiddenLabel value={similarityThreshold_Strict} autoComplete="off" type="number" fullWidth={true} disabled={inputDisabled} InputProps={{ disableUnderline: true }} inputProps={{ style: inputStyle }} onChange={(event) => updateRecord(id, 'similarityThreshold_Strict', (event.target.value))} />
					<MDBox mt={-1.9} ml={-0.5}>
						<Slider value={record.similarityThreshold_Strict * 100.0} min={0.0} max={100.0} disabled={inputDisabled} size="small" onChange={(event) => updateRecord(id, 'similarityThreshold_Strict', (event.target.value / 100.0))} />
					</MDBox>
				</MDBox>
			),
			action: (
				<MDBox>
					<MDTypography variant="caption" color="success" fontWeight="medium" sx={canSave ? enabledStyle : disabledStyle} onClick={() => { if (canSave) { saveRecord(id); } }}>
						Save
					</MDTypography>
						&nbsp; | &nbsp;
					<MDTypography variant="caption" color="text" fontWeight="medium" sx={canCancel ? enabledStyle : disabledStyle} onClick={() => { if (canCancel) { cancelEdit(id); } }}>
						Cancel
					</MDTypography>
				</MDBox>
			),
		});
	};


	// ---- Skeleton Table  -----------------------------------------------------------------------------

	const SkeletonTable = (
		<MDBox sx={{ p: 2, cursor: "wait" }}>
			<Skeleton variant="rectangle" animation="wave" xs={12} height={40} />
			<Skeleton variant="rectangle" xs={12} height={40} />
			<Skeleton variant="rectangle" animation="wave" xs={12} height={40} />
			<Skeleton variant="rectangle" xs={12} height={40} />
			<Skeleton variant="rectangle" animation="wave" xs={12} height={40} />
			<Skeleton variant="rectangle" xs={12} height={40} />
			<Skeleton variant="rectangle" animation="wave" xs={12} height={40} />
		</MDBox>
	);


	// ---- Rows ----------------------------------------------------------------------------------------

	// Rows
	const rows = Records.map(record => {
		const isBeingEdited = _.includes(editing, record.id);
		return (isBeingEdited ? Row_Edit(record) : Row(record));
	});


	//
	if (!_.includes(editing, -1)) {
		rows.push({
			name: (
				<MDButton variant="outlined" color="info" size="small" onClick={addRecord}>
					<Icon sx={{ fontWeight: "bold" }}>add</Icon>
				</MDButton>
			),
			weightT: (" "), weightT_Possible: (" "), similarityT: (" "), similarityT_Strict: (" "), action: (" "), 
		});
	}	


	// ---- Component -----------------------------------------------------------------------------------
	
	return loading ? (SkeletonTable) : (
		<MDBox>
			<DataTable
				table={{ columns: Columns, rows }}
				isSorted={false}
				entriesPerPage={false}
				showTotalEntries={false}
				noEndBorder
			/>
			{SuccessToast}
			{ErrorToast}
			{DeleteDialog}
		</MDBox>
	);
}

// Setting default values for the props
LabelTypesTable.defaultProps = {
	setShowProgress: null,
};

// Typechecking props
LabelTypesTable.propTypes = {
	setShowProgress: PropTypes.func,
};
