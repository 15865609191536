// @mui material
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import CircularProgress from '@mui/material/CircularProgress';

// MD-2 React
import MDBox from "components/MDComponents/MDBox";
import MDTypography from "components/MDComponents/MDTypography";
import MDButton from "components/MDComponents/MDButton";

// MD-2 React example
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

// Data
import LabelTypesTable from "views/labelTypes/data/labelTypesTable";
import React from "react";

//
function LabelTypesView() {

	//
	const [showProgress, setShowProgress] = React.useState(false);
	function GradientCircularProgress() {
		return (
			<React.Fragment>
				<svg width={0} height={0}>
					<defs>
						<linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
							<stop offset="0%" stopColor="#e01cd5" />
							<stop offset="100%" stopColor="#1CB5E0" />
						</linearGradient>
					</defs>
				</svg>
				<CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' }, animationDuration: '777ms' }} thickness={6} size={35} />
			</React.Fragment>
		);
	}
	  

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox pt={6} pb={3}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Card>
							<MDBox mx={2} mt={-3} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
								<Grid container sx={{ justifyContent: "space-between", alignItems: "center" }} >
									<Grid item xs={5} mt={3} mb={2.5} ml={3}>
										<MDTypography variant="h6" color="white">
											LabelTypes
										</MDTypography>
									</Grid>
									<Grid item xs={3}>{showProgress ? (<MDBox sx={{ pt: 1, pr: 3, textAlign: "right" }}><GradientCircularProgress /></MDBox>) : (null)}</Grid>
								</Grid>
							</MDBox>
							<MDBox pt={3} px={2}>
								<LabelTypesTable setShowProgress={setShowProgress} />
							</MDBox>
						</Card>
					</Grid>

					<Grid item xs={12}>
						<Card>
							<MDBox p={2} lineHeight={0}>
								<MDTypography variant="h6">Stuff</MDTypography>
								<MDTypography variant="button" color="text" fontWeight="regular">
									What stuff it be? We soon find out, just be patient.
								</MDTypography>
							</MDBox>
							{/* <Divider component="li" /> */}
							<MDBox paddingLeft={1} paddingBottom={1}>
								<MDButton component="a" href="/labelType" variant="gradient" color="info">
									<Icon sx={{ fontWeight: "bold" }}>add</Icon>&nbsp;New Record
								</MDButton>
							</MDBox>
						</Card>
					</Grid>
				</Grid>


			</MDBox>
			<Footer />
		</DashboardLayout>
	);
}

export default LabelTypesView;
