// MD-2 React Base Styles
import colors from "assets/theme-dark/base/colors";
import typography from "assets/theme-dark/base/typography";

// MD-2 React helper functions
import alpha from "assets/theme-dark/functions/alpha";
import pxToRem from "assets/theme-dark/functions/pxToRem";


const { dark, info, white, background } = colors;
const { size } = typography;

const inputFilled = {
	styleOverrides: {
		root: {
			fontSize: size.md,
			backgroundColor: alpha(background.default, 0x50),

			"&:hover": {
				backgroundColor: alpha(background.default, 0x80),
			},

			"&.Mui-focused": {
				backgroundColor: alpha(background.default, 0xa0),
			},
		},

		input: { // Same for Outlined & Filled 
			color: white.main,
		},

		inputSizeSmall: { // Styles applied to the input element if size="small"
			fontSize: size.xs,
			padding: pxToRem(10),
		},

		multiline: { // Styles applied to the root element if multiline={true}
			color: white.main,
			padding: 0,
		},
		
		underline: {
			'&:before': {
				borderBottomColor: dark.main
			},
			'&:hover:not(.Mui-disabled)::before': {
				borderBottomColor: info.main
			},
			'&:after': {
				borderBottomColor: info.main
			},
		},
	},
};

export default inputFilled;
