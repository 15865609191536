/**
	The alpha() function helps you to convert an opaque color into a transparent one
 */

function alpha(hexColor, transparency = 0x20) {
    if (transparency <= 1 && transparency >= 0) { transparency = transparency * 255; }
    transparency = Math.min(Math.ceil(transparency), 255);
    if (isNaN(transparency)) { transparency = 0; }
    return `${hexColor}${transparency.toString(16)}`; 
};

export default alpha;
