// @mui material
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// MD-2 React
import MDBox from "components/MDComponents/MDBox";
import MDTypography from "components/MDComponents/MDTypography";
import MDButton from "components/MDComponents/MDButton";

// MD-2 React example
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import DataTable from "components/Tables/DataTable";

// Data
import FeatureTypesTableData from "views/featureTypes/data/featureTypesTableData";
import React from "react";

//
function FeatureTypes() {
	const [tableData, setTableData] = React.useState({
		columns: FeatureTypesTableData.columns(),
		rows: []
	});

	//
	React.useEffect(() => {
		let isSubscribed = true; // To prevent multi-call race conditions?

		//
		(async () => {
			const tableData = await FeatureTypesTableData.tableData();
			isSubscribed && setTableData(tableData);
		})();

		//
		return (() => isSubscribed = false);
	}, []);

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox pt={6} pb={3}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Card>
							<MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
								<MDTypography variant="h6" color="white">
									FeatureType Table
								</MDTypography>
							</MDBox>
							<MDBox pt={3}>
								<DataTable
									table={tableData}
									isSorted={false}
									entriesPerPage={false}
									showTotalEntries={false}
									noEndBorder
									customButtons={
										<MDButton variant="gradient" color="info" onClick={() => { console.log('PooBar'); }}>
											<Icon sx={{ fontWeight: "bold" }}>add</Icon>
											&nbsp;Add
										</MDButton>
									}
								/>
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
			<Footer />
		</DashboardLayout>
	);
}

export default FeatureTypes;
