// MD-2 React Base Styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

// MD-2 React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { dark, info, transparent } = colors;
const { size } = typography;

const inputFilled = {
	styleOverrides: {
		root: {
			backgroundColor: transparent.main,
			fontSize: size.md,
		},

		input: { // Same for Outlined & Filled 
			color: dark.main,
			backgroundColor: transparent.grey[20],
		},

		inputSizeSmall: { // Styles applied to the input element if size="small"
			fontSize: size.xs,
			padding: pxToRem(10),
		},

		multiline: { // Styles applied to the root element if multiline={true}
			color: dark.main,
			padding: 0,
		},
		
		underline: {
			'&:before': {
				borderBottomColor: dark.main
			},
			'&:hover:not(.Mui-disabled)::before': {
				borderBottomColor: info.main
			},
			'&:after': {
				borderBottomColor: info.main
			},
		},
	},
};

export default inputFilled;
