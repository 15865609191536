import _ from 'lodash';


export default class Network {
	//
	static async Request(options = {}, secondary = null) {
		return Network.Get(options, secondary);
	}

	static async GetJson(options = {}, secondary = null) {
		return Network.Get(options, secondary);
	}

	static async GetText(options = {}, secondary = null) {
		const operation = new NetworkOperation(options, secondary);
		operation.options.resultType = 'text';
		return operation.execute();
	}

	static async GetBlob(options = {}, secondary = null) {
		const operation = new NetworkOperation(options, secondary);
		operation.options.resultType = 'blob';
		return operation.execute();
	}

	static async Get(options = {}, secondary = null) {
		const operation = new NetworkOperation(options, secondary);
		return operation.execute();
	}

	static async Put(options = {}, secondary = null) {
		const operation = new NetworkOperation(options, secondary);
		operation.requestOptions.method = 'put';
		return operation.execute();
	}
	
	static async Post(options = {}, secondary = null) {
		const operation = new NetworkOperation(options, secondary);
		operation.requestOptions.method = 'post';
		return operation.execute();
	}

	static async Delete(options = {}, secondary = null) {
		const operation = new NetworkOperation(options, secondary);
		operation.requestOptions.method = 'delete';
		return operation.execute();
	}

}

class NetworkOperation {
	requestOptions = null;
	request = null;
	response = null;
	result = null;
	statuses = ['Uninitialized', 'Ready', 'Set', 'Request', 'Response', 'Complete'];
	status = 0;
	options = {
		resultType: 'json',
		applyDefaults: true
	};
	defaults = {
		headers: {
			"Content-Type": "application/json"
		}
	};
	errors = [];
	get hasErrors() { return (this.errors?.length > 0); }
	

	constructor(_options = {}, secondary = null) {
		if (_.isString(_options)) { // (url, options)
			this.setRequestOptions({ url: _options, ...secondary });
		}
		else if (isRequestObject(_options)) { // (Request)
			this.setRequest(_options);
		}
		else if (_options.url?.length > 0) { // (options)
			this.setRequestOptions(_options);
		}
		//
		if (secondary?.resultType) { options.resultType = secondary.resultType; }
		if (_.isBoolean(secondary?.applyDefaults)) { options.applyDefaults = secondary.applyDefaults; }
	}

	setRequestOptions(_requestOptions) {
		if (this.status >= this.statuses.indexOf('Set') || this.request !== null) {
			throw new Error('NetworkOperation.setRequestOptions, request has already been set.');
		}
		this.requestOptions = _requestOptions;
		this.status = this.statuses.indexOf('Ready');
	}

	setRequest(_request) {
		if (this.status >= this.statuses.indexOf('Set') || this.request !== null) {
			throw new Error('NetworkOperation.setRequest, request has already been set.');
		}
		if (!isRequestObject(_request)) {
			throw new Error('NetworkOperation.setRequest, not a valid Request object.');
		}
		this.request = _request;
		this.status = this.statuses.indexOf('Set');
	}

	async execute() {
		if (this.status < this.statuses.indexOf('Ready') || (this.requestOptions === null && this.request === null)) {
			throw new Error('NetworkOperation.execute, NetworkOperation is uninitialized.');
		}

		// Set Request, if not set
		if (this.status < this.statuses.indexOf('Set')) {

			// Defaults
			if (this.options.applyDefaults) {
				_.defaultsDeep(this.requestOptions, this.defaults);
			}

			// Request
			this.setRequest(new Request(this.requestOptions.url, _.pick(this.requestOptions, _requestConstructorKeys)));
		}

		// OnExecuteBegin
		await this.onExecuteBegin();

		// Request
		try {
			this.status = this.statuses.indexOf('Request');
			this.response = await fetch(this.request);
		}
		catch (error) {
			if (!this.handleFetchError(error)) {
				this.status = this.statuses.indexOf('Complete');
				throw error;
			}
		}

		// Response
		try {
			await this.processResponseCode();
		}
		catch (error) {
			if (!this.handleResultCodeError(error)) {
				this.status = this.statuses.indexOf('Complete');
				throw error;
			}
		}

		// Result
		try {
			this.status = this.statuses.indexOf('Response');
			this.result = await this.processResponse();
		}
		catch (error) {
			if (!this.handleResultError(error)) {
				this.status = this.statuses.indexOf('Complete');
				throw error;
			}
		}

		// Complete
		this.status = this.statuses.indexOf('Complete');

		// OnExecuteEnd
		await this.onExecuteEnd();

		return this.result;
	}

	async processResponseCode () {
		if (!this.response.ok) {
			const responseClone = this.response.clone();
			const responseText = await responseClone.text();
			throw new Error(`HTTP Status Code: ${responseClone.status}. (${responseText})`);
		}
	}
	
	async processResponse() {
		if (this.options.resultType == 'none') { return null; }
		//
		const responseClone = this.response.clone();
		switch(this.options.resultType) {
			case 'text': return responseClone.text();
			case 'blob': return responseClone.blob();
			case 'formData': return responseClone.formData();
			case 'arrayBuffer': return responseClone.arrayBuffer();
			default: return this.response.json();
		}
	}

	// ---- Error Handlers ------------------------------------------------------------------------------
	// 'execute' will re-throw the Error if these functions do not return true.

	handleFetchError(error) {
		this.errors.push(error);
		console.log(`NetworkOperation.fetchError: ${error}`);
	}

	handleResultCodeError(error) {
		this.errors.push(error);
		console.log(`NetworkOperation.resultCodeError: ${error}`);
	}

	handleResultError(error) {
		this.errors.push(error);
		console.log(`NetworkOperation.resultError: ${error}`);
	}


	// --------------------------------------------------------------------------------------------------

	async onExecuteBegin() {
		// Override me
	}

	async onExecuteEnd() {
		// Override me
	}


	// -------------------------------------------------------------------------------------------------

	isNetworkOperation() {
		return true;
	}
}


const _requestKeys = ['body','bodyUsed','cache','credentials','destination','headers','integrity','keepalive','method','mode','redirect','referrer','referrerPolicy','signal','url'];
const _requestConstructorKeys = _.without(_requestKeys, 'bodyUsed', 'destination', 'url');
function isRequestObject(_object) {
	return (_.intersection(_.keysIn(_object), _requestKeys).length == 15);
}



// Request object:
// -------------------------------------------------------------------------------------------------------------------------
// body				- Can be a: string, ArrayBuffer, TypedArray, DataView, Blob, File, URLSearchParamsFormData
// bodyUsed			- (readonly) Stores true or false to indicate whether or not the body has been used in a request yet.
// cache			- Contains the cache mode of the request (e.g., default, reload, no-cache).
// credentials		- Contains the credentials of the request (e.g., omit, same-origin, include). The default is same-origin.
// destination		- (readonly) A string describing the type of content being requested. (usually an empty string)
// headers			- Contains the associated Headers object of the request. { "header-name": "header-value" }
// integrity		- Contains the sub-resource integrity value of the request (e.g., sha256-BpfBw7ivV8q2jLiT13fxDYAe2tJllusRSZ273h2nFSE=). (usually an empty string)
// keepalive		- true/false
// method			- Contains the request's method (GET, POST, etc.)
// mode				- Contains the mode of the request (e.g., cors, no-cors, same-origin, navigate.)
// redirect			- Contains the mode for how redirects are handled. It may be one of follow, error, or manual.
// referrer			- Contains the referrer of the request (e.g., client). (value will = https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/{referrer})
// referrerPolicy	- Contains the referrer policy of the request (e.g., no-referrer (enum.ReferrerPolicy)).
// signal			- Returns the AbortSignal associated with the request
// url				- (readonly?) Contains the URL of the request.
