// MD-2 React Base Styles
import colors from "assets/theme-dark/base/colors";
import typography from "assets/theme-dark/base/typography";
import borders from "assets/theme-dark/base/borders";

// MD-2 React Helper Functions
import rgba from "assets/theme-dark/functions/rgba";

const { info, inputBorderColor, grey, white } = colors;
const { size, lineHeight, fontWeightLight } = typography;
const { borderWidth } = borders;

const input = {
	styleOverrides: {
		root: {

			"&:hover:not(.Mui-disabled):before": {
				borderBottom: `${borderWidth[1]} solid ${rgba(inputBorderColor, 0.6)}`,
			},

			"&:before": {
				borderColor: rgba(inputBorderColor, 0.6),
			},

			"&:after": {
				borderColor: info.main,
			},
		},

		input: {
			// color: alpha(white.main, 0xcc),
			color: white.main,
			fontSize: size.sm,
			fontWeight: fontWeightLight,
			lineHeight: lineHeight.sm,
			padding: 0,
			letterSpacing: "0.03333em",

			"&::-webkit-input-placeholder": {
				color: grey[100],
			},
			
			'&::-webkit-outer-spin-button': {
				'WebkitAppearance': 'none',
				// margin: 0
			},
			'&::-webkit-inner-spin-button': {
				'WebkitAppearance': 'none',
				// margin: 0
			}
		},
	},
};

export default input;
