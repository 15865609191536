// MD-2 React Base Styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";

const { info, inputBorderColor } = colors;
const { size, fontWeightLight, lineHeight } = typography;
const { borderWidth } = borders;

const input = {
	styleOverrides: {
		root: {

			"&:hover:not(.Mui-disabled):before": {
				borderBottom: `${borderWidth[1]} solid ${inputBorderColor}`,
			},

			"&:before": {
				borderColor: inputBorderColor,
			},

			"&:after": {
				borderColor: info.main,
			},

			input: {
				color: colors.grey[600],
				fontSize: size.sm,
				fontWeight: fontWeightLight,
				lineHeight: lineHeight.sm,
				letterSpacing: "0.03333em",
				padding: 0,

				'&::-webkit-outer-spin-button': {
					'WebkitAppearance': 'none',
					// margin: 0
				},
				'&::-webkit-inner-spin-button': {
					'WebkitAppearance': 'none',
					// margin: 0
				}
			}
		},
	},
};

export default input;
