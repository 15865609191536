import colors from "assets/theme-dark/base/colors";
import pxToRem from "assets/theme-dark/functions/pxToRem";
import alpha from "assets/theme-dark/functions/alpha";
import sliderClasses from '@mui/material/Slider/sliderClasses';


const { dark, black, background } = colors;
const sliderBorderColor = alpha(dark.highlight, 0xaa);
const lightBackgroundColor = dark.highlight;
const darkBackgroundColor = background.default;

//
const slider = {
	styleOverrides: {

		root: {
			padding: 0,
			'@media (pointer: coarse)': {
				padding: 0,
			},
			
			// ---- Small ---------------------------------------------------------------------------------------
			//
			"&.MuiSlider-sizeSmall": {
				
				"& .MuiSlider-rail": {
					height: pxToRem(4),
					width: '101%',
				},
				
				"& .MuiSlider-track": {
					height: pxToRem(5),
					border: `1px solid ${sliderBorderColor}`,
					width: '100%',
				},

				"& .MuiSlider-thumb": {
					width: pxToRem(12),
					height: pxToRem(12),
					boxShadow: `0px 2px 0px 0px ${alpha(black.main, 0x35)}`, // 0.16 == 0x28

					'&::after': {
						width: 28,
						height: 28,
					},
					
					[`&:hover, &.${sliderClasses.focusVisible}`]: {
						boxShadow: `0px 0px 0px 2px ${alpha(dark.main, 0x42)}`, // 0.16 == 0x28
						'@media (hover: none)': {
							boxShadow: 'none'
						}
					},

					[`&.${sliderClasses.active}`]: {
						boxShadow: `0px 0px 0px 6px ${alpha(dark.main, 0x42)}`, // 0.16 == 0x28
					},
				},

				"&:hover": {
					"& .MuiSlider-rail": {
						height: pxToRem(6),
					},
					"& .MuiSlider-track": {
						height: pxToRem(8),
					},
					"& .MuiSlider-thumb": {
						height: pxToRem(14),
						width: pxToRem(14),
					},
				},
			},


			// ---- Medium (Default) ----------------------------------------------------------------------------
			//
			"& .MuiSlider-thumb": {
				'&::after': {
					width: 42,
					height: 42,
				},
			},

			"&:hover": {
				"& .MuiSlider-rail": {
					height: pxToRem(10),
				},
				"& .MuiSlider-track": {
					height: pxToRem(12),
				},
				"& .MuiSlider-thumb": {
					height: pxToRem(21),
					width: pxToRem(21),
				},
			},
		},
			

		rail: {
			height: pxToRem(7),
			background: lightBackgroundColor,
			opacity: 1,
		},

		track: {
			height: pxToRem(9),
			background: darkBackgroundColor,
			borderColor: sliderBorderColor,
			zIndex: 1,
		},

		thumb: {
			width: pxToRem(14),
			height: pxToRem(14),
			backgroundColor: darkBackgroundColor,
			border: `1px solid ${sliderBorderColor}`,
			zIndex: 10,

			[`&:hover, &.${sliderClasses.focusVisible}`]: {
				boxShadow: `0px 0px 0px 8px ${alpha(dark.main, 0x42)}`, // 0.16 == 0x28
				'@media (hover: none)': {
					boxShadow: 'none'
				}
			},

			[`&.${sliderClasses.active}`]: {
				boxShadow: `0px 0px 0px 14px ${alpha(dark.main, 0x42)}`, // 0.16 == 0x28
			},
		},

	},
};

export default slider;
