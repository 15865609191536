// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material
import Fade from "@mui/material/Fade";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


// MD-2 React
import MDTypography from "components/MDComponents/MDTypography";

// Custom styles for the MDDialog
import MDDialogIconRoot from "components/MDComponents/MDDialog/MDDialogIconRoot";

// MD-2 React context
import { useMaterialUIController } from "context";
import _ from "lodash";

function MDDialog({ color, icon, title, content, agreeContent, disagreeContent, deleteId, onAgree, onDisagree, close, bgWhite, ...rest }) {
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;

	let titleColor;
	let dividerColor;
	let buttonColor;

	if (bgWhite) {
		titleColor = color;
		buttonColor = color;
		dividerColor = false;
	} else if (color === "light") {
		titleColor = darkMode ? "inherit" : "dark";
		buttonColor = darkMode ? "inherit" : "text";
		dividerColor = false;
	} else {
		titleColor = "white";
		buttonColor = "white";
		dividerColor = true;
	}

	const _onAgree = () => {
		if (_.isFunction(onAgree)) {
			onAgree(deleteId);
		}
		else {
			close();
		}
	};
	const _onDisagree = () => {
		if (_.isFunction(onDisagree)) { onDisagree(); }
		else { close(); }
	};

	return (
		<Dialog
			TransitionComponent={Fade}
			open={open}
			onClose={close}
			maxWidth="100%"
			{...rest}
		>
			<DialogTitle
				sx={{
					backgroundColor: ({ palette }) =>
					darkMode ? palette.background.card : palette[color] || palette.white.main,
				}}>
				<MDTypography variant="inherit" fontWeight="medium" color={titleColor} textGradient={bgWhite} >
					{title}
				</MDTypography>
			</DialogTitle>
			<DialogContent
				sx={{
					backgroundColor: ({ palette }) =>
					darkMode ? palette.background.card : palette[color] || palette.white.main,
				}}
			>
				<DialogContentText
					p={1.5}
					sx={{
						fontSize: ({ typography: { size } }) => size.sm,
						color: ({ palette: { white, text } }) => {
							let colorValue = bgWhite || color === "light" ? text.main : white.main;
							if (darkMode) { colorValue = color === "light" ? "inherit" : white.main; }
							return colorValue;
						},
					}}
				>
					<MDDialogIconRoot fontSize="small" ownerState={{ color, bgWhite }} sx={{ display: 'inline' }}>
						{icon}
					</MDDialogIconRoot>
					{content}
				</DialogContentText>
			</DialogContent>
			<DialogActions
				sx={{
					backgroundColor: ({ palette }) =>
					darkMode ? palette.background.card : palette[color] || palette.white.main,
				}}
			>
				<Button onClick={_onDisagree}>
					<MDTypography variant="inherit" fontWeight="medium" color={buttonColor} textGradient={bgWhite} >
						{disagreeContent}
					</MDTypography>
				</Button>
				<Button onClick={_onAgree} autoFocus>
					<MDTypography variant="inherit" fontWeight="medium" color={buttonColor} textGradient={bgWhite} >
						{agreeContent}
					</MDTypography>
				</Button>
			</DialogActions>
		</Dialog>
	);
}

// Setting default values for the props of MDDialog
MDDialog.defaultProps = {
	bgWhite: false,
	color: "info",
	agreeContent: 'OK',
	disagreeContent: 'CANCEL',
};

// Typechecking props for MDDialog
MDDialog.propTypes = {
	color: PropTypes.oneOf([
		"primary",
		"secondary",
		"info",
		"success",
		"warning",
		"error",
		"dark",
		"light",
	]),
	icon: PropTypes.node,
	title: PropTypes.string.isRequired,
	content: PropTypes.node.isRequired,
	agreeContent: PropTypes.node,
	disagreeContent: PropTypes.node,
	deleteId: PropTypes.number,
	onAgree: PropTypes.func,
	onDisagree: PropTypes.func,
	close: PropTypes.func.isRequired,
	bgWhite: PropTypes.bool,
};

export default MDDialog;
