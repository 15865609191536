// Config (static)
const config = {
	api: {
		url: 'classifyapi.softatomic.cloud',
		version: 'v1',
		controllers: {
			LabelType: 'ClassificationLabelTypes',
			FeatureType: 'ClassificationFeatureTypes'
		}
	}
};

export default config;
