import React from "react";

//
import Network from "logic/network";

// react-router-dom
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";

// MD-2 React
import MDBox from "components/MDComponents/MDBox";
import MDTypography from "components/MDComponents/MDTypography";
import _ from "lodash";

//
const classifyApiURL = 'classifyapi.softatomic.cloud';
const anythingNotANumber = /[^0-9]/;

function Breadcrumbs({ icon, title, route, light, showTitle, aliasResolvers }) {
	const routes = route.slice(0, -1);

	// Aliases
	const [aliases, setAliases] = React.useState([]);
	React.useEffect(() => {
		let isSubscribed = true;
		(async () => {
			const _aliases = await resolveAliases(route, aliasResolvers);
			isSubscribed && setAliases(_aliases);
		})();
		return (() => isSubscribed = false);
	}, []);

	const resolveAliases = async (nodes, resolvers = []) => {
		const _aliases = [];
		let resolverIndex = 0;
		let aliasResolver = resolvers[resolverIndex] || null;
		for (const nodeName of nodes) {
			if (aliasResolver == null || nodeName.match(anythingNotANumber)) {
				_aliases.push(nodeName);
				continue;
			}
			const alias = await resolveAlias(nodeName, aliasResolver.object, aliasResolver.objectKey);
			_aliases.push(alias || nodeName);
			resolverIndex++;
			aliasResolver = resolvers[resolverIndex] || null;
		}
		return _aliases;
	};

	const resolveAlias = async (id, object, objectKey) => {
		try {
			const record = await Network.Get(`https://${classifyApiURL}/v1/${object}/${id}`);
			return record?.[objectKey];
		}
		catch (error) {
			console.log(`Breadcrumbs.resolveAlias, ${error}`);
			return id;
		}
	};

	// Title
	title = title || aliases[aliases.length - 1] || route[route.length - 1];

	
	return (
		<MDBox mr={{ xs: 0, xl: 8 }}>
			<MuiBreadcrumbs
				sx={{
					"& .MuiBreadcrumbs-separator": {
						color: ({ palette: { white, grey } }) => (light ? white.main : grey[600]),
					},
				}}
			>
				<Link to="/">
					<MDTypography
						component="span"
						variant="body2"
						color={light ? "white" : "dark"}
						opacity={light ? 0.8 : 0.5}
						sx={{ lineHeight: 0 }}
					>
						<Icon>{icon}</Icon>
					</MDTypography>
				</Link>
				{routes.map((el, idx) => (
					<Link to={`/${el}`} key={el}>
						<MDTypography
							component="span"
							variant="button"
							fontWeight="regular"
							textTransform="capitalize"
							color={light ? "white" : "dark"}
							opacity={light ? 0.8 : 0.5}
							sx={{ lineHeight: 0 }}
						>
							{(aliases[idx] || el)}
						</MDTypography>
					</Link>
				))}
				<MDTypography
					variant="button"
					fontWeight="regular"
					textTransform="capitalize"
					color={light ? "white" : "dark"}
					sx={{ lineHeight: 0 }}
				>
					{title.replace("-", " ")}
				</MDTypography>
			</MuiBreadcrumbs>
			<MDTypography
				fontWeight="bold"
				textTransform="capitalize"
				variant="h6"
				color={light ? "white" : "dark"}
				noWrap
			>
				{showTitle && title.replace("-", " ")}
				&nbsp;
			</MDTypography>
		</MDBox>
	);
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
	title: null,
	showTitle: true,
	light: false,
	aliasResolvers: []
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
	icon: PropTypes.node.isRequired,
	title: PropTypes.string,
	showTitle: PropTypes.bool,
	route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
	light: PropTypes.bool,
	aliasResolvers: PropTypes.array
};

export default Breadcrumbs;
