/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

//
import Network from "logic/network"

// MD-2 React
import MDBox from "components/MDComponents/MDBox";
import MDTypography from "components/MDComponents/MDTypography";

// Data
export default class FeatureTypesTableData {

	static columns() {
		return [
			{ Header: "name", accessor: "name", width: "45%", align: "left" },
			{ Header: "probabilityM", accessor: "probabilityM", align: "center" },
			{ Header: "probabilityU", accessor: "probabilityU", align: "center" },
			{ Header: "agreementWeightOverride", accessor: "agreementWeightOverride", align: "center" },
			{ Header: "disagreementWeightOverride", accessor: "disagreementWeightOverride", align: "center" },
			{ Header: "action", accessor: "action", align: "center" }
		];
	}

	static async rows() {

		// Name & Description
		const NameAndDescriptionCell = ({ name, description }) => (
			<MDBox lineHeight={1} textAlign="left">
				<MDTypography display="block" variant="caption" color="text" fontWeight="medium">{name || ''}</MDTypography>
				<MDTypography variant="caption">{description || ''}</MDTypography>
			</MDBox>
		);

		// Row
		const FeatureTypeRow = ({ name, description, probabilityM, probabilityU, agreementWeightOverride, disagreementWeightOverride }) => ({
			name: (<NameAndDescriptionCell name={name} description={description} />),
			probabilityM: (
				<MDTypography variant="caption" color="text" fontWeight="medium">
					{probabilityM}
				</MDTypography>
			),
			probabilityU: (
				<MDTypography variant="caption" color="text" fontWeight="medium">
					{probabilityU}
				</MDTypography>
			),
			agreementWeightOverride: (
				<MDTypography variant="caption" color="text" fontWeight="medium">
					{(agreementWeightOverride || '')}
				</MDTypography>
			),
			disagreementWeightOverride: (
				<MDTypography variant="caption" color="text" fontWeight="medium">
					{(disagreementWeightOverride || '')}
				</MDTypography>
			),
			action: (
				<MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
					Edit
				</MDTypography>
			),
		});

		// Get Data
		const classifyApiURL = 'classifyapi.softatomic.cloud';
		const FeatureTypes = await Network.Get(`https://${classifyApiURL}/v1/ClassificationFeatureTypes`);

		// Rows
		return FeatureTypes.map(featureType => FeatureTypeRow(featureType));
	}

	static async tableData() {
		return {
			columns: FeatureTypesTableData.columns(),
			rows: await FeatureTypesTableData.rows()
		};
	}

}
