// MD-2 React Base Styles
import colors from "assets/theme-dark/base/colors";
import borders from "assets/theme-dark/base/borders";
import boxShadows from "assets/theme-dark/base/boxShadows";

// MD-2 React Helper Function
import rgba from "assets/theme-dark/functions/rgba";

const { black, background } = colors;
const { borderWidth, borderRadius } = borders;
const { md } = boxShadows;

const card = {
	styleOverrides: {
		root: {
			display: "flex",
			flexDirection: "column",
			position: "relative",
			minWidth: 0,
			wordWrap: "break-word",
			backgroundImage: "none",
			backgroundColor: background.card,
			backgroundClip: "border-box",
			border: `${borderWidth[0]} solid ${rgba(black.main, 0.125)}`,
			borderRadius: borderRadius.xl,
			boxShadow: md,
			overflow: "visible",
		},
	},
};

export default card;
